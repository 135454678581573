<template>
  <div class="activityList">
    <van-sticky>
      <div class="search_box">
        <van-icon name="cross" @click="$router.push('/')" />
        <van-search
          v-model="value"
          placeholder="请输入搜索关键词"
          @search="reset"
        />
      </div>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item
          v-model="status"
          :options="options"
          :title="status === null ? stateTitle : null"
          @change="reset"
        />
      </van-dropdown-menu>
    </van-sticky>
    <div v-show="list.length === 0" class="nothing">
      <img src="@/assets/images/nothing.png" alt="" />
      <span>暂无数据</span>
    </div>
    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="list.length === 0 ? '' : '已经没有更多了哦'"
      @load="onLoad"
    >
      <div class="list_box">
        <div
          class="list_item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetails(item)"
        >
          <div class="left">
            <img :src="item.coverUrl" alt="" v-if="item.coverUrl" class="img" />
            <div v-else class="img"></div>
            <!-- <div class="price">
              {{ item.needPay ? item.price + "元" : "免费" }}
            </div> -->
            <div class="register" v-if="item.isRegister">
              限{{ item.numberRegister
              }}{{ item.needParent === 1 ? "组" : "人" }}，<span>{{
                item.size || 0
              }}</span
              >{{ item.needParent === 1 ? "组" : "人" }}已报名
            </div>
            <div class="status" :style="{ backgroundColor: item.statusColor }">
              {{ item.statusText }}
            </div>
          </div>
          <div class="right">
            <h3>
              {{ item.activityName || item.exhibitName }}
            </h3>
            <p v-if="item.isRegister">
              报名日期：{{
                item.registrationStartTime + " 至 " + item.registrationEndTime
              }}
            </p>
            <p>
              {{ typeText }}日期：{{ item.exhibitDate || item.activityDate }}
            </p>
            <p>{{ typeText }}时间：{{ item.activityTime }}</p>
            <!-- <p>{{ typeText }}地点：{{ item.activityAddress }}</p> -->
            <p>年龄限制：{{ item.minAge + "-" + item.maxAge }}岁</p>
            <!-- <div>
              <span v-if="item.numberOnline"
                >活动限制{{ item.numberOnline }}人</span
              >
              <span v-if="item.numberRegister && item.isRegister"
                >报名限制{{ item.numberRegister }}人</span
              >
              <span class="lottery" v-if="item.needChoose && item.isRegister"
                >摇号</span
              >
            </div> -->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { activityList, exhibitList } from "@/api/ActivityList";
export default {
  name: "ActivityList",
  data() {
    return {
      value: null,
      status: 1,
      stateTitle: "临展状态",
      options: [],
      type: "",
      loading: false,
      finished: false,
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      getListApi: null,
      typeText: "",
    };
  },
  computed: {},
  created() {
    this.type = this.$route.query.type;
    if (this.type === "临展详情") {
      this.getListApi = exhibitList;
      this.stateTitle = "临展状态";
      this.typeText = "临展";
      this.options = [
        { text: "全部", value: null },
        { text: "报名未开始", value: 0, color: "#fd4d4f" },
        { text: "报名进行中", value: 1, color: "#4bd863" },
        { text: "临展未开始", value: 3, color: "#fd4d4f" },
        { text: "临展进行中", value: 4, color: "#ff9502" },
        { text: "临展已结束", value: 5, color: "#cccccc" },
        { text: "临展已取消", value: -1, color: "#cccccc" },
      ];
    } else if (this.type === "活动详情") {
      this.getListApi = activityList;
      this.stateTitle = "活动状态";
      this.typeText = "活动";
      this.options = [
        { text: "全部", value: null },
        { text: "报名未开始", value: 0, color: "#fd4d4f" },
        { text: "报名进行中", value: 1, color: "#4bd863" },
        { text: "活动未开始", value: 3, color: "#fd4d4f" },
        { text: "活动进行中", value: 4, color: "#ff9502" },
        { text: "活动已结束", value: 5, color: "#cccccc" },
        { text: "活动已取消", value: -1, color: "#cccccc" },
      ];
    }
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.status,
      };
      if (this.typeText === "活动") {
        data.activityName = this.value || null;
      } else {
        data.exhibitName = this.value || null;
      }
      this.getListApi(data)
        .then((res) => {
          res.data.rows.map((item) => {
            if (this.typeText === "活动") {
              item.activityTime =
                item.activityStartTime + " 至 " + item.activityEndTime;
            } else {
              item.activityTime =
                item.exhibitStartTime + " 至 " + item.exhibitEndTime;
            }
            this.options.map((v) => {
              if (v.value === item.status) {
                item.statusText = v.text;
                item.statusColor = v.color;
              }
            });
          });
          this.list = this.list.concat(res.data.rows);
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
            this.list = this.list.filter(
              (obj, index) =>
                this.list.findIndex((item) => item.id === obj.id) === index
            );
          }
        });
    },
    onLoad() {
      this.pageNum++;
      this.getList();
    },
    reset() {
      this.total = 0;
      this.pageNum = 1;
      this.loading = true;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    toDetails(item) {
      this.$router.push({
        path: "/activityDetails",
        query: { id: item.id, type: this.type },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.activityList {
  background-color: #f5f6fa;
  min-height: 100vh;
  .search_box {
    background-color: #fff;
    display: flex;
    align-items: center;
    .van-icon-cross {
      margin-left: 10px;
      font-size: 18px;
    }
    .van-search {
      width: 100%;
    }
  }
  .list_box {
    padding: 10px;
    .list_item {
      display: flex;
      padding: 10px;
      background-color: #fff;
      .left {
        position: relative;
        width: 120px;
        min-width: 120px;
        .img {
          min-width: 120px;
          width: 120px;
          height: 120px;
          object-fit: cover;
          // object-fit: contain;
        }
        .status {
          position: absolute;
          left: 0;
          top: 0;
          height: 25px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 25px;
          padding: 0 10px 0 5px;
          border-radius: 0 0 30px 0;
        }
        .price {
          font-weight: 400;
          font-size: 12px;
          color: #fd4d4f;
        }
        .register {
          font-weight: 400;
          font-size: 12px;
          span {
            color: #0b6cf9;
          }
        }
      }
      .right {
        width: 100%;
        padding: 0 5px;
        margin-left: 10px;
        h3 {
          font-family: "PingFangSC-Medium";
          font-weight: 500;
          color: #333333;
          font-size: 14px;
        }
        p {
          font-size: 12px;
          line-height: 24px;
          font-family: "PingFangSC-Regular";
          font-weight: 400;
          font-size: 12px;
          color: #666666;
        }
        > div {
          display: flex;
          span {
            padding: 2px;
            font-family: "PingFangSC-Regular";
            font-weight: 400;
            font-size: 10px;
            color: #076cfa;
            text-align: center;
            line-height: 12px;
            border: 1px solid #076cfa;
          }
          span + span {
            margin-left: 5px;
          }
          .lottery {
            border: 1px solid #fd4d4f;
            color: #fd4d4f;
          }
        }
      }
    }
    .list_item + .list_item {
      margin-top: 10px;
    }
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    img {
      width: 60%;
    }
    span {
      font-size: 16px;
      color: #999;
    }
  }
}
</style>
